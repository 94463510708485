<template>
    <icon-base class="icon-download" :width="size + 'px'" :fill="color" viewBox="0 0 25.784 24.47">
        <g id="Download_icon" data-name="Download icon" transform="translate(0.25 0.25)">
            <path id="Download_icon-2" data-name="Download icon" d="M3080.013-68.03c-.77,0-.94-.37-1-.69a.6.6,0,0,1-.013-.128.79.79,0,0,1,.154-.462.745.745,0,0,1,.48-.32,3.894,3.894,0,0,1,.48-.02h23.2c.59.01.969.32.97.8v.022c-.01.59-.52.8-.99.8ZM3091-73.58c-2.15-2.149-4.3-4.289-6.439-6.449a.926.926,0,0,1-.324-.653.819.819,0,0,1,.1-.378.813.813,0,0,1,.73-.419,1.381,1.381,0,0,1,.2.02,1.271,1.271,0,0,1,.56.39q1.8,1.816,3.61,3.62a3.055,3.055,0,0,0,.32.3l1.05,1v-14.7c0-.06,0-.118,0-.175a1.064,1.064,0,0,1,.012-.175.845.845,0,0,1,.8-.8.818.818,0,0,1,.83.82,1.057,1.057,0,0,1,.014.171c0,.059,0,.119,0,.179v14.74l4.99-4.991a1.118,1.118,0,0,1,.55-.38.773.773,0,0,1,.91.38.862.862,0,0,1,.13.434.749.749,0,0,1-.229.507c-.39.429-.81.84-1.219,1.25l-1.58,1.57q-1.846,1.86-3.71,3.71a.977.977,0,0,1-.672.345A.9.9,0,0,1,3091-73.58Z" transform="translate(-3079 92)" :fill="color" :stroke="color" stroke-width="0.5"/>
        </g>
    </icon-base>
</template>

<script>
export default {
    name: 'iconDownload',
    components: { iconBase: () => import('@/common/components/iconBase.vue') },
    props: {
        size: {
            type: Number,
            default: 20
        },
        color: {
            type: String,
            default: 'var(--gray-1001)'
        },
        enableBackground: {
            type: Boolean
        }
    }
}
</script>

<style scoped>
    .st5 {
        fill: #ffffff;
    }
</style>
